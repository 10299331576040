import { setCMSBarWidth } from './cms-bar-width'


document.addEventListener('DOMContentLoaded', () => {
    /* Menu control */
    const body = document.getElementsByTagName("BODY")[0]
    const toggleMenu = document.getElementById('toggleMenu')
    const openMenu = document.getElementById('openMenu')
    const closeMenu = document.getElementById('closeMenu')

    function menu(element, add) {
        toggleMenu.addEventListener('click', function() {
            element.classList.toggle(add)
        })
    }

    menu(body, 'show-body')
    menu(openMenu, 'hide-icon')
    menu(closeMenu, 'show-icon')

    const structure = document.querySelector("a[href=?structure]")
    if (structure) {
        structure.addEventListener('click', () => {setTimeout(setCMSBarWidth, 1000)}, true)
    }
})
